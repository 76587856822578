import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Masonry from "react-masonry-component";
import SimpleReactLightbox, {
	SRLWrapper,
	useLightbox,
} from "simple-react-lightbox";

// Images
import PortStu1 from "../../images/portfolio/Student1.jpg";
import PortStu2 from "../../images/portfolio/Student2.jpg";
import PortStu3 from "../../images/portfolio/Student3.jpg";
import PortStu4 from "../../images/portfolio/Student4.png";
import PortStu5 from "../../images/portfolio/Student5.JPG";
import PortStu6 from "../../images/portfolio/Student6.JPG";
import PortAch1 from "../../images/portfolio/Achievement1.jpg";
import PortAch2 from "../../images/portfolio/Achievement2.jpg";
import PortAch3 from "../../images/portfolio/Achievement3.jpg";
import PortAch4 from "../../images/portfolio/Achievement4.jpg";
import PortAch5 from "../../images/portfolio/Achievement5.jpg";
import PortAch6 from "../../images/portfolio/Achievement6.jpg";
import PortAch7 from "../../images/portfolio/Achievement7.jpg";
import PortAch8 from "../../images/portfolio/Achievement8.JPG";
import PortAch9 from "../../images/portfolio/Achievement9.JPG";
import PortAch10 from "../../images/portfolio/Achievement10.png";
import PortAch11 from "../../images/portfolio/Achievement11.png";
import PortDigido1_2022 from "../../images/portfolio/Digido2022_1.png";
import PortDigido2_2022 from "../../images/portfolio/Digido2022_2.png";
import PortDigido3_2022 from "../../images/portfolio/Digido2022_3.png";
import PortDigido4_2022 from "../../images/portfolio/Digido2022_4.png";
import PortDigido5_2022 from "../../images/portfolio/Digido2022_5.jpg";
import PortDigido6_2022 from "../../images/portfolio/Digido2022_6.jpg";
import PortDigido7_2022 from "../../images/portfolio/Digido2022_7.jpg";
import PortDigido1_2023 from "../../images/portfolio/Digido2023_1.png";
import PortDigido2_2023 from "../../images/portfolio/Digido2023_2.png";
import PortDigido3_2023 from "../../images/portfolio/Digido2023_3.png";
import PortDigido4_2023 from "../../images/portfolio/Digido2023_4.jpg";
import PortDigido5_2023 from "../../images/portfolio/Digido2023_5.jpg";
import PortDigido6_2023 from "../../images/portfolio/Digido2023_6.jpg";

import PortAIO1 from "../../images/portfolio/AIO1.jpg";
import PortAIO2 from "../../images/portfolio/AIO2.jpg";
import PortAIO3 from "../../images/portfolio/AIO3.jpg";
import PortAIO4 from "../../images/portfolio/AIO4.jpg";
import PortAIO5 from "../../images/portfolio/AIO5.jpg";
import PortAIO6 from "../../images/portfolio/AIO6.jpg";
import PortAIO7 from "../../images/portfolio/AIO7.jpg";
import PortAIO8 from "../../images/portfolio/AIO8.jpg";
import PortAIO9 from "../../images/portfolio/AIO9.jpg";
import PortAIO10 from "../../images/portfolio/AIO10.jpg";
import PortAIO11 from "../../images/portfolio/AIO11.png";
import PortAwardCeremony1 from "../../images/portfolio/AwardCeremony1.jpg";
import PortAwardCeremony2 from "../../images/portfolio/AwardCeremony2.jpg";
import PortAwardCeremony3 from "../../images/portfolio/AwardCeremony3.jpg";
import PortAwardCeremony4 from "../../images/portfolio/AwardCeremony4.jpg";
import PortAwardCeremony5 from "../../images/portfolio/AwardCeremony5.jpg";
import PortAwardCeremony6 from "../../images/portfolio/AwardCeremony6.jpg";
import PortAwardCeremony7 from "../../images/portfolio/AwardCeremony7.jpg";
import PortAwardCeremony8 from "../../images/portfolio/AwardCeremony8.jpg";
import PortAwardCeremony9 from "../../images/portfolio/AwardCeremony9.jpg";
import PortAwardCeremony10 from "../../images/portfolio/AwardCeremony10.jpg";
import PortAwardCeremony11 from "../../images/portfolio/AwardCeremony11.jpg";
import PortAwardCeremony12 from "../../images/portfolio/AwardCeremony12.jpg";
import PortAwardCeremony13 from "../../images/portfolio/AwardCeremony13.jpg";
import PortAwardCeremony14 from "../../images/portfolio/AwardCeremony14.jpg";
import PortAwardCeremony15 from "../../images/portfolio/AwardCeremony15.jpg";
import PortAwardCeremony16 from "../../images/portfolio/AwardCeremony16.jpg";
import PortAwardCeremony17 from "../../images/portfolio/AwardCeremony17.jpg";
import PortAwardCeremony18 from "../../images/portfolio/AwardCeremony18.jpg";
import PortAwardCeremony19 from "../../images/portfolio/AwardCeremony19.jpg";
import PortGiftedChild1 from "../../images/portfolio/GiftedChild1.jpg";
import PortGiftedChild2 from "../../images/portfolio/GiftedChild2.jpg";
import PortGiftedChild3 from "../../images/portfolio/GiftedChild3.jpg";
import PortGiftedChild4 from "../../images/portfolio/GiftedChild4.JPG";
import PortGiftedChild5 from "../../images/portfolio/GiftedChild5.JPG";
import PortGiftedChild6 from "../../images/portfolio/GiftedChild6.JPG";
import PortGiftedChild7 from "../../images/portfolio/GiftedChild7.jpg";
import PortGiftedChild8 from "../../images/portfolio/GiftedChild8.jpg";

import PortAIMLTrain1 from "../../images/portfolio/AIMLTrain1.jpg";
import PortAIMLTrain2 from "../../images/portfolio/AIMLTrain2.jpg";
import PortAIMLTrain3 from "../../images/portfolio/AIMLTrain3.jpg";
import PortAIMLTrain4 from "../../images/portfolio/AIMLTrain4.jpg";
import PortAIMLTrain5 from "../../images/portfolio/AIMLTrain5.jpg";
import PortAIMLTrain6 from "../../images/portfolio/AIMLTrain6.jpg";
import PortAIMLTrain7 from "../../images/portfolio/AIMLTrain7.png";

import { withTranslation } from "react-i18next";

import i18next from "i18next";
import ReactGA from 'react-ga';

// Magnific Anchor
const MagnificAnchor = (props) => {
	const { openLightbox } = useLightbox();

	return (
		<Link
			to={"#"}
			onClick={() => openLightbox(props.imageToOpen)}
			className="magnific-anchor"
		>
			<i className="ti-search"></i>
		</Link>
	);
};

const options = {
	settings: {
		overlayColor: "rgba(0,0,0,0.9)",
		backgroundColor: "#f7b205",
		slideAnimationType: "slide",
	},
	buttons: {
		backgroundColor: "#f7b205",
		iconColor: "rgba(255, 255, 255, 1)",
		showAutoplayButton: false,
		showDownloadButton: false,
	},
	caption: {
		captionColor: "#a6cfa5",
		captionFontFamily: "Raleway, sans-serif",
		captionFontWeight: "300",
		captionTextTransform: "uppercase",
	},
};

const FilterList = ({ dataFilter, defaultTag, activeFilter }) => {
	return (
		<li
			className={`${activeFilter ? "btn active" : "btn"}`}
			onClick={() => defaultTag(dataFilter)}
		>
			<Link to={"#"}>{dataFilter}</Link>
		</li>
	);
};
const TagDescriptionList = [
	{
		tag: "Portf_Achievement_AIO",
		title:"Achievement_AIO_Title",
		description: "Achievement_AIO_description",
	},
	{
		tag: "Portf_Achievement_YICT",
		title:"Achievement_YoungICT_Title",
		description: "Achievement_YoungICT_description",
	},
	{
		tag: "Portf_Achievement_ICAS",
		title: "Achievement_ICAS_Title",
		description: "Achievement_ICAS_description",
	},
	{
		tag: "Portf_Achievement_Lego",
		title: "Achievement_Lego_Title",
		description: "Achievement_Lego_description",
	},
	{
		tag: "Portf_Achievement_Bebras",
		title: "Achievement_Bebras_Title",
		description: "Achievement_Bebras_description",
	},
	{
		tag: "Portf_Community_Digido_2022",
		title: "Community_Digido_Title_2022",
		description: "Community_Digido_2022_description",
	},
	{
		tag: "Portf_Community_Digido_2023",
		title: "Community_Digido_Title_2023",
		description: "Community_Digido_2023_description",
	},
	{
		tag: "Portf_Community_GiftedChild",
		title: "Community_GiftedChild_Title",
		description: "Community_GiftedChild_description",
	},
	{
		tag: "Portf_ClassEngagement",
		title: "ClassEngagement_Title",
		description: "ClassEngagement_description",
	},
	{
		tag: "Portf_Award_Ceremony_2023",
		title: "Award_Ceremony_2023_Title",
		description: "Award_Ceremony_2023_description",
	},
	{
		tag: "Portf_Award_Ceremony_2024",
		title: "Award_Ceremony_2024_Title",
		description: "Award_Ceremony_2024_description",
	},
	{
		tag: "Portf_IOAI_2024_Train_AIML",
		title: "Portf_IOAI_2024_Train_AIML_Title",
		description: "Portf_IOAI_2024_Train_AIML_description",
	},
];

const content = [
	// Student images
	{
		thumb: PortStu2,
		LightImg: PortStu2,
		tag: "Portf_ClassEngagement",
		title: "Certificate of Level 2",
	},
	{
		thumb: PortStu3,
		LightImg: PortStu3,
		tag: "Portf_ClassEngagement",
		title: "Certificate of Level 3",
	},
	{
		thumb: PortStu4,
		LightImg: PortStu4,
		tag: "Portf_ClassEngagement",
		title: "Students in Class",
	},
	{
		thumb: PortStu5,
		LightImg: PortStu5,
		tag: "Portf_ClassEngagement",
		title: "Students in Class",
	},
	{
		thumb: PortStu6,
		LightImg: PortStu6,
		tag: "Portf_ClassEngagement",
		title: "Students in Class",
	},
	{
		thumb: PortStu1,
		LightImg: PortStu1,
		tag: "Portf_ClassEngagement",
		title: "Class of Level 1",
	},
	// AIO images
	{
		thumb: PortAIO1,
		LightImg: PortAIO1,
		tag: "Portf_Achievement_AIO",
		title: "AIO Gold Medal",
	},
	{
		thumb: PortAIO2,
		LightImg: PortAIO2,
		tag: "Portf_Achievement_AIO",
		title: "AIO Bronze Medal",
	},
	{
		thumb: PortAIO3,
		LightImg: PortAIO3,
		tag: "Portf_Achievement_AIO",
		title: "AIO Bronze Medal",
	},
	{
		thumb: PortAIO4,
		LightImg: PortAIO4,
		tag: "Portf_Achievement_AIO",
		title: "AIO Credit",
	},
	{
		thumb: PortAIO5,
		LightImg: PortAIO5,
		tag: "Portf_Achievement_AIO",
		title: "AIO Credit",
	},
	{
		thumb: PortAIO6,
		LightImg: PortAIO6,
		tag: "Portf_Achievement_AIO",
		title: "AIO Credit",
	},
	{
		thumb: PortAIO7,
		LightImg: PortAIO7,
		tag: "Portf_Achievement_AIO",
		title: "AIO Medals",
	},
	{
		thumb: PortAIO8,
		LightImg: PortAIO8,
		tag: "Portf_Achievement_AIO",
		title: "AIO Gold Medal",
	},
	{
		thumb: PortAIO9,
		LightImg: PortAIO9,
		tag: "Portf_Achievement_AIO",
		title: "AIO Credit Medals",
	},
	{
		thumb: PortAIO10,
		LightImg: PortAIO10,
		tag: "Portf_Achievement_AIO",
		title: "AIO Bronze Medals",
	},
	{
		thumb: PortAIO11,
		LightImg: PortAIO11,
		tag: "Portf_Achievement_AIO",
		title: "AIO Bronze Medals",
	},
	// Achievement images
	{
		thumb: PortAch1,
		LightImg: PortAch1,
		tag: "Portf_Achievement_YICT",
		title: "Young ICT South Australia 1st Place",
	},
	{
		thumb: PortAch2,
		LightImg: PortAch2,
		tag: "Portf_Achievement_ICAS",
		title: "ICAS Year 4 Digital Technologies South Australia 2020 Medal",
	},
	{
		thumb: PortAch3,
		LightImg: PortAch3,
		tag: "Portf_Achievement_ICAS",
		title: "ICAS Year 4 Digital Technologies South Australia 2020 High Distinction",
	},
	{
		thumb: PortAch4,
		LightImg: PortAch4,
		tag: "Portf_Achievement_ICAS",
		title: "ICAS Year 5 Digital Technologies South Australia 2021 High Distinction",
	},
	{
		thumb: PortAch5,
		LightImg: PortAch5,
		tag: "Portf_Achievement_Lego",
		title: "2022 First Lego League National Champion South Australia",
	},
	{
		thumb: PortAch6,
		LightImg: PortAch6,
		tag: "Portf_Achievement_Bebras",
		title: "Grade 6 Round 1 of the 2021 Bebras Australia Computational Thinking Challenge High Distinction",
	},
	{
		thumb: PortAch7,
		LightImg: PortAch7,
		tag: "Portf_Achievement_Bebras",
		title: "Grade 6 Round 2 of the 2021 Bebras Australia Computational Thinking Challenge High Distinction",
	},
	{
		thumb: PortAch8,
		LightImg: PortAch8,
		tag: "Portf_Achievement_Bebras",
		title: "Bebras Australia Computational Thinking Challenge 2023 High Distinction",
	},
	{
		thumb: PortAch9,
		LightImg: PortAch9,
		tag: "Portf_Achievement_Bebras",
		title: "Bebras Australia Computational Thinking Challenge 2023 High Distinction",
	},
	{
		thumb: PortAch10,
		LightImg: PortAch10,
		tag: "Portf_Achievement_Lego",
		title: "Robot Cup 1",
	},
	{
		thumb: PortAch11,
		LightImg: PortAch11,
		tag: "Portf_Achievement_Lego",
		title: "Robot Cup 2",
	},
	// Digido images 2022
	{
		thumb: PortDigido1_2022,
		LightImg: PortDigido1_2022,
		tag: "Portf_Community_Digido_2022",
		title: "Digido 1 2022",
	},
	{
		thumb: PortDigido2_2022,
		LightImg: PortDigido2_2022,
		tag: "Portf_Community_Digido_2022",
		title: "Digido 2 2022",
	},
	{
		thumb: PortDigido3_2022,
		LightImg: PortDigido3_2022,
		tag: "Portf_Community_Digido_2022",
		title: "Digido 3 2022",
	},
	{
		thumb: PortDigido4_2022,
		LightImg: PortDigido4_2022,
		tag: "Portf_Community_Digido_2022",
		title: "Digido 4 2022",
	},
	{
		thumb: PortDigido5_2022,
		LightImg: PortDigido5_2022,
		tag: "Portf_Community_Digido_2022",
		title: "Digido 5 2022",
	},
	{
		thumb: PortDigido6_2022,
		LightImg: PortDigido6_2022,
		tag: "Portf_Community_Digido_2022",
		title: "Digido 6 2022",
	},
	{
		thumb: PortDigido7_2022,
		LightImg: PortDigido7_2022,
		tag: "Portf_Community_Digido_2022",
		title: "Digido 7 2022",
	},
	// Digido images 2023
	{
		thumb: PortDigido1_2023,
		LightImg: PortDigido1_2023,
		tag: "Portf_Community_Digido_2023",
		title: "Digido 1 2023",
	},
	{
		thumb: PortDigido2_2023,
		LightImg: PortDigido2_2023,
		tag: "Portf_Community_Digido_2023",
		title: "Digido 2 2023",
	},
	{
		thumb: PortDigido3_2023,
		LightImg: PortDigido3_2023,
		tag: "Portf_Community_Digido_2023",
		title: "Digido 3 2023",
	},
	{
		thumb: PortDigido4_2023,
		LightImg: PortDigido4_2023,
		tag: "Portf_Community_Digido_2023",
		title: "Digido 4 2023",
	},
	{
		thumb: PortDigido5_2023,
		LightImg: PortDigido5_2023,
		tag: "Portf_Community_Digido_2023",
		title: "Digido 5 2023",
	},
	{
		thumb: PortDigido6_2023,
		LightImg: PortDigido6_2023,
		tag: "Portf_Community_Digido_2023",
		title: "Digido 6 2023",
	},
	// Gifted Child images
	{
		thumb: PortGiftedChild1,
		LightImg: PortGiftedChild1,
		tag: "Portf_Community_GiftedChild",
		title: "Gifted Child 1",
	},
	{
		thumb: PortGiftedChild2,
		LightImg: PortGiftedChild2,
		tag: "Portf_Community_GiftedChild",
		title: "Gifted Child 2",
	},
	{
		thumb: PortGiftedChild3,
		LightImg: PortGiftedChild3,
		tag: "Portf_Community_GiftedChild",
		title: "Gifted Child 3",
	},
	{
		thumb: PortGiftedChild4,
		LightImg: PortGiftedChild4,
		tag: "Portf_Community_GiftedChild",
		title: "Gifted Child 4",
	},
	{
		thumb: PortGiftedChild5,
		LightImg: PortGiftedChild5,
		tag: "Portf_Community_GiftedChild",
		title: "Gifted Child 5",
	},
	{
		thumb: PortGiftedChild6,
		LightImg: PortGiftedChild6,
		tag: "Portf_Community_GiftedChild",
		title: "Gifted Child 6",
	},
	{
		thumb: PortGiftedChild7,
		LightImg: PortGiftedChild7,
		tag: "Portf_Community_GiftedChild",
		title: "Gifted Child 7",
	},
	{
		thumb: PortGiftedChild8,
		LightImg: PortGiftedChild8,
		tag: "Portf_Community_GiftedChild",
		title: "Gifted Child 8",
	},
	// Award Ceremony images
	{
		thumb: PortAwardCeremony1,
		LightImg: PortAwardCeremony1,
		tag: "Portf_Award_Ceremony_2023",
		title: "Award Ceremony 1",
	},
	{
		thumb: PortAwardCeremony2,
		LightImg: PortAwardCeremony2,
		tag: "Portf_Award_Ceremony_2023",
		title: "Award Ceremony 2",
	},
	{
		thumb: PortAwardCeremony3,
		LightImg: PortAwardCeremony3,
		tag: "Portf_Award_Ceremony_2023",
		title: "Award Ceremony 3",
	},
	{
		thumb: PortAwardCeremony4,
		LightImg: PortAwardCeremony4,
		tag: "Portf_Award_Ceremony_2023",
		title: "Award Ceremony 4",
	},
	{
		thumb: PortAwardCeremony5,
		LightImg: PortAwardCeremony5,
		tag: "Portf_Award_Ceremony_2023",
		title: "Award Ceremony 5",
	},
	{
		thumb: PortAwardCeremony6,
		LightImg: PortAwardCeremony6,
		tag: "Portf_Award_Ceremony_2023",
		title: "Award Ceremony 6",
	},
	{
		thumb: PortAwardCeremony7,
		LightImg: PortAwardCeremony7,
		tag: "Portf_Award_Ceremony_2023",
		title: "Award Ceremony 7",
	},
	{
		thumb: PortAwardCeremony8,
		LightImg: PortAwardCeremony8,
		tag: "Portf_Award_Ceremony_2023",
		title: "Award Ceremony 8",
	},
	{
		thumb: PortAwardCeremony9,
		LightImg: PortAwardCeremony9,
		tag: "Portf_Award_Ceremony_2023",
		title: "Award Ceremony 9",
	},
	{
		thumb: PortAwardCeremony10,
		LightImg: PortAwardCeremony10,
		tag: "Portf_Award_Ceremony_2023",
		title: "Award Ceremony 10",
	},
	{
		thumb: PortAwardCeremony11,
		LightImg: PortAwardCeremony11,
		tag: "Portf_Award_Ceremony_2023",
		title: "Award Ceremony 11",
	},
	{
		thumb: PortAwardCeremony12,
		LightImg: PortAwardCeremony12,
		tag: "Portf_Award_Ceremony_2023",
		title: "Award Ceremony 12",
	},
	{
		thumb: PortAwardCeremony13,
		LightImg: PortAwardCeremony13,
		tag: "Portf_Award_Ceremony_2023",
		title: "Award Ceremony 13",
	},
	{
		thumb: PortAwardCeremony14,
		LightImg: PortAwardCeremony14,
		tag: "Portf_Award_Ceremony_2023",
		title: "Award Ceremony 14",
	},
	{
		thumb: PortAwardCeremony15,
		LightImg: PortAwardCeremony15,
		tag: "Portf_Award_Ceremony_2023",
		title: "Award Ceremony 15",
	},
	{
		thumb: PortAwardCeremony16,
		LightImg: PortAwardCeremony16,
		tag: "Portf_Award_Ceremony_2023",
		title: "Award Ceremony 16",
	},
	{
		thumb: PortAwardCeremony17,
		LightImg: PortAwardCeremony17,
		tag: "Portf_Award_Ceremony_2023",
		title: "Award Ceremony 17",
	},
	{
		thumb: PortAwardCeremony18,
		LightImg: PortAwardCeremony18,
		tag: "Portf_Award_Ceremony_2023",
		title: "Award Ceremony 18",
	},
	{
		thumb: PortAwardCeremony19,
		LightImg: PortAwardCeremony19,
		tag: "Portf_Award_Ceremony_2023",
		title: "Award Ceremony 19",
	},
	{
		thumb: PortAIMLTrain1,
		LightImg: PortAIMLTrain1,
		tag: "Portf_IOAI_2024_Train_AIML",
		title: "IOAI 2024 National Train Camp with AIML 1",
	},
	{
		thumb: PortAIMLTrain2,
		LightImg: PortAIMLTrain2,
		tag: "Portf_IOAI_2024_Train_AIML",
		title: "IOAI 2024 National Train Camp with AIML 2",
	},
	{
		thumb: PortAIMLTrain3,
		LightImg: PortAIMLTrain3,
		tag: "Portf_IOAI_2024_Train_AIML",
		title: "IOAI 2024 National Train Camp with AIML 3",
	},
	{
		thumb: PortAIMLTrain4,
		LightImg: PortAIMLTrain4,
		tag: "Portf_IOAI_2024_Train_AIML",
		title: "IOAI 2024 National Train Camp with AIML 4",
	},
	{
		thumb: PortAIMLTrain5,
		LightImg: PortAIMLTrain5,
		tag: "Portf_IOAI_2024_Train_AIML",
		title: "IOAI 2024 National Train Camp with AIML 5",
	},
	{
		thumb: PortAIMLTrain6,
		LightImg: PortAIMLTrain6,
		tag: "Portf_IOAI_2024_Train_AIML",
		title: "IOAI 2024 National Train Camp with AIML 6",
	},
	{
		thumb: PortAIMLTrain7,
		LightImg: PortAIMLTrain7,
		tag: "Portf_IOAI_2024_Train_AIML",
		title: "IOAI 2024 National Train Camp with AIML 7",
	}
];
// group tags together
const tags_achievement = [
	"Portf_Achievement_AIO",
	"Portf_Achievement_YICT",
	"Portf_Achievement_ICAS",
	"Portf_Achievement_Lego",
	"Portf_Achievement_Bebras"
];
const tags_community = [
	"Portf_Community_Digido_2022",
	"Portf_Community_Digido_2023",
	"Portf_Community_GiftedChild"
];
const tags_award = [
	"Portf_Award_Ceremony_2023",
	"Portf_Award_Ceremony_2024"
];
const tags_classEngagement = [
	"Portf_ClassEngagement"
];
const tags_IOAI = [
	"Portf_IOAI_2024_Train_AIML"
];
function MasonryContent() {
	const [filteredImages, setFilteredImages] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState(i18next.t("Portf_ClassEngagement"));

	useEffect(() => {
		let tagsToFilter = [];

		if (selectedCategory === i18next.t("Portf_Achievements")) {
			tagsToFilter = tags_achievement;
		} else if (selectedCategory === i18next.t("Portf_Community")) {
			tagsToFilter = tags_community;
		} else if (selectedCategory === i18next.t("Portf_Award_Ceremony")) {
			tagsToFilter = tags_award;
		} else if (selectedCategory === i18next.t("Portf_ClassEngagement")) {
			tagsToFilter = tags_classEngagement;
		} else if (selectedCategory === i18next.t("Portf_IOAI_2024")) {
			tagsToFilter = tags_IOAI;
		}

		setFilteredImages(
			content.filter((image) =>
				tagsToFilter.some((tag) => image.tag.includes(tag))
			)
		);
	}, [selectedCategory]);

	const allTags = [...tags_achievement, ...tags_community, ...tags_award, ...tags_classEngagement, ...tags_IOAI];

	return (
		<>
			<div className="feature-filters clearfix center m-b40">
				<ul className="filters" data-toggle="buttons">
					<FilterList
						dataFilter={i18next.t("Portf_ClassEngagement")}
						defaultTag={setSelectedCategory}
						activeFilter={selectedCategory === i18next.t("Portf_ClassEngagement")}
					/>
					<FilterList
						dataFilter={i18next.t("Portf_Achievements")}
						defaultTag={setSelectedCategory}
						activeFilter={selectedCategory === i18next.t("Portf_Achievements")}
					/>
					<FilterList
						dataFilter={i18next.t("Portf_Community")}
						defaultTag={setSelectedCategory}
						activeFilter={selectedCategory === i18next.t("Portf_Community")}
					/>
					<FilterList
						dataFilter={i18next.t("Portf_Award_Ceremony")}
						defaultTag={setSelectedCategory}
						activeFilter={selectedCategory === i18next.t("Portf_Award_Ceremony")}
					/>
					<FilterList
						dataFilter={i18next.t("Portf_IOAI_2024")}
						defaultTag={setSelectedCategory}
						activeFilter={selectedCategory === i18next.t("Portf_IOAI_2024")}
					/>
				</ul>
			</div>

			<div>
				{allTags.map((tag) => {
					const imagesForTag = filteredImages.filter((image) => image.tag.includes(tag));

					// Find titles and descriptions
					const tagData = TagDescriptionList.find(
						(item) => item.tag === tag
					);
					const tagTitle = tagData?.title;
					const tagDescription = tagData?.description;

					if (imagesForTag.length === 0) {
						return null;
					}

					return (
						<div key={tag}>
							<h3>{i18next.t(tagTitle)}</h3>
							<p>{i18next.t(tagDescription)}</p>
							<SimpleReactLightbox>
								<SRLWrapper options={options}>
									<Masonry className="ttr-gallery-listing">
										{imagesForTag.map((item, index) => (
											<div
												className="action-card col-lg-3 col-md-4 col-sm-6"
												key={index}
											>
												<div className="ttr-box portfolio-bx">
													<div className="ttr-media media-ov2 media-effect">
														<img alt="" src={item.thumb} />
														<div className="ov-box">
															<div className="overlay-icon align-m">
																<MagnificAnchor imageToOpen={index} />
															</div>
															<div className="portfolio-info-bx">
																<h4>{item.title}</h4>
															</div>
														</div>
													</div>
												</div>
											</div>
										))}
									</Masonry>
								</SRLWrapper>
							</SimpleReactLightbox>
						</div>
					);
				})}
			</div>
		</>
	);
}



class PortfolioContent extends Component {
	componentDidMount() {
		ReactGA.pageview(window.location.pathname);
	}
	render() {
		return (
			<>
				<MasonryContent />
			</>
		);
	}
}

export default withTranslation()(PortfolioContent);