import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header/header1";
import bannerImg from "../../images/banner/banner2.jpg";
import { withTranslation } from "react-i18next";
import ReactGA from 'react-ga';

const Round_1_Student_List = [
    {
        name: "Adam Jiabao Li",
        gender: "Male",
        state: "SA",
        pass: false,
    },
    {
        name: "Alice Mao",
        gender: "Female",
        state: "NSW",
        pass: false,
    },
    {
        name: "Brayden Xu",
        gender: "Male",
        state: "SA",
        pass: false,
    },
    {
        name: "Darcy Sutton",
        gender: "Female",
        state: "TAS",
        pass: true,
    },
    {
        name: "Florence Fang",
        gender: "Female",
        state: "SA",
        pass: true,
    },
    {
        name: "Jayden Yue",
        gender: "Male",
        state: "SA",
        pass: true,
    },
    {
        name: "Max Chen",
        gender: "Male",
        state: "SA",
        pass: false,
    },
    {
        name: "Oscar Zhou",
        gender: "Male",
        state: "SA",
        pass: true,
    },
    {
        name: "Sunny Yuxin Wang",
        gender: "Female",
        state: "SA",
        pass: false,
    },
    {
        name: "Yao Tian",
        gender: "Female",
        state: "SA",
        pass: true,
    },
];

//round 2 list
const Round_2_Student_List = Round_1_Student_List.filter(student => student.pass === true);

// update student name
const updatedStudents = Round_2_Student_List.map(student => {
    if (student.name === "Yao Tian") {
        return { ...student, name: "Yao Tian (Reserved)" };
    }
    return student;
});

class IOAIApplicationJourney extends Component {
    componentDidMount() {
        ReactGA.pageview(window.location.pathname);
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <Header />
                <div className="page-content">
                    <div className="content-block" id="content-area">
                        <div className="page-banner ovbl-dark" style={{ backgroundImage: `url(${bannerImg})` }}>
                            <div className="container">
                                <div className="page-banner-entry">
                                    <h1 className="text-white">{t("IOAI_Application_Title")}</h1>
                                </div>
                            </div>
                        </div>
                        <div className="breadcrumb-row">
                            <div className="container">
                                <ul className="list-inline">
                                    <li>
                                        <Link to="/">{t("IOAI_Application_LinkHome")}</Link>
                                    </li>
                                    <li>{t("IOAI_Application_LinkIOAI_Journey")}</li>
                                </ul>
                            </div>
                        </div>
                        {/* journey section */}
                        <div className="content-block m-tb50" id="content-area">
                            <div className="container">
                                <h1 className="col-md-12 heading-bx style1 text-center">{t("IOAI_Journey_Title")}</h1>
                                <div className="row">
                                    <div className="col-lg-7 mx-auto">

                                        {/* <!-- Timeline --> */}
                                        <ul className="timeline">
                                            {/* Application Process */}
                                            <li className="timeline-item bg-white rounded ml-3 p-4 shadow">
                                                <div className="timeline-arrow"></div>
                                                <h2 className="h5 mb-0">{t("IOAI_Journey_Application_Title")}</h2><span className="small timeline-text-color"><i className="fa fa-clock-o mr-1"></i>8 April, 2024 - 5 May, 2024</span>
                                                <p className="text-small mt-2 font-weight-light">{t("IOAI_Journey_Application_Content")}</p>
                                                <p className="text-small mt-2 font-weight-light">{t("IOAI_Journey_Application_Content_1")}</p>
                                                <ul className="m-l20">
                                                    <li className="text-small mt-2 font-weight-light">{t("IOAI_Journey_Application_Content_2")}</li>
                                                    <li className="text-small mt-2 font-weight-light">{t("IOAI_Journey_Application_Content_3")}</li>
                                                    <li className="text-small mt-2 font-weight-light">{t("IOAI_Journey_Application_Content_4")}</li>
                                                    <li className="text-small mt-2 font-weight-light">{t("IOAI_Journey_Application_Content_5")}</li>
                                                    <li className="text-small mt-2 font-weight-light">{t("IOAI_Journey_Application_Content_6")}</li>
                                                 </ul>
                                                <p className="text-small mt-2 font-weight-light">{t("IOAI_Journey_Application_Content_7")}</p>
                                            </li>
                                            {/* Round 1 */}
                                            <li className="timeline-item bg-white rounded ml-3 p-4 shadow">
                                                <div className="timeline-arrow"></div>
                                                <h2 className="h5 mb-0">{t("IOAI_Journey_Round_1_Title")}</h2><span className="small timeline-text-color"><i className="fa fa-clock-o mr-1"></i>6 May, 2024 - 11 May, 2024</span>
                                                <p className="text-small mt-2 font-weight-light">{t("IOAI_Journey_Round_1_Content")}</p>
                                                {/* table of round 1 student list */}
                                                <div className="container mt-4">
                                                    <div className="row justify-content-center">
                                                        <div className="col-12 col-md-10">
                                                            <table className="table table-hover">
                                                                <thead className="thead-light">
                                                                    <tr>
                                                                        <th className="text-left">Name</th>
                                                                        <th className="text-left">Gender</th>
                                                                        <th className="text-left">State</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {Round_1_Student_List.map((student, index) => (
                                                                        <tr key={index}>
                                                                            <td className="text-left">{student.name}</td>
                                                                            <td className="text-left">{student.gender}</td>
                                                                            <td className="text-left">{student.state}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* end of table */}
                                                <p className="text-small mt-2 font-weight-light">Thanks for our scientific committee.</p>
                                            </li>
                                            {/* Round 2 */}
                                            <li className="timeline-item bg-white rounded ml-3 p-4 shadow">
                                                <div className="timeline-arrow"></div>
                                                <h2 className="h5 mb-0">{t("IOAI_Journey_Round_2_Title")}</h2><span className="small timeline-text-color"><i className="fa fa-clock-o mr-1"></i>12 May, 2024 - 27 May, 2024</span>
                                                <p className="text-small mt-2 font-weight-light">{t("IOAI_Journey_Round_2_Content_1")}</p>
                                                <p className="text-small mt-2 font-weight-light">{t("IOAI_Journey_Round_2_Content_2")}</p>
                                                <p className="text-small mt-2 font-weight-light">{t("IOAI_Journey_Round_2_Content_3")}</p>
                                                <p className="text-small mt-2 font-weight-light">{t("IOAI_Journey_Round_2_Content_4")}</p>
                                                
                                                {/* table of round 2 student list */}
                                                <div className="container mt-4">
                                                    <div className="row justify-content-center">
                                                        <div className="col-12 col-md-10">
                                                            <table className="table table-hover">
                                                                <thead className="thead-light">
                                                                <tr>
                                                                    <th>Name</th>
                                                                    <th>Gender</th>
                                                                    <th>State</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {updatedStudents.map((student, index) => (
                                                                    <tr key={index}>
                                                                        <td className="text-left">{student.name}</td>
                                                                        <td className="text-left">{student.gender}</td>
                                                                        <td className="text-left">{student.state}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* end of table */}
                                            </li>
                                            {/* Training */}
                                            <li className="timeline-item bg-white rounded ml-3 p-4 shadow">
                                                <div className="timeline-arrow"></div>
                                                <h2 className="h5 mb-0">{t("IOAI_Journey_Training_Title")}</h2><span className="small timeline-text-color"><i className="fa fa-clock-o mr-1"></i>2 June, 2024 - 4 August, 2024</span>
                                                <p className="text-small mt-2 font-weight-light">{t("IOAI_Journey_Training_Content")}</p>
                                            </li>
                                            {/* Practical Round */}
                                            <li className="timeline-item bg-white rounded ml-3 p-4 shadow">
                                                <div className="timeline-arrow"></div>
                                                <h2 className="h5 mb-0">{t("IOAI_Journey_Practical_Round_Title")}</h2><span className="small timeline-text-color"><i className="fa fa-clock-o mr-1"></i>9 August, 2024 - 15 August, 2024</span>
                                                <p className="text-small mt-2 font-weight-light">{t("IOAI_Journey_Practical_Round_Content")}</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- End --> */}

                    </div>
                    </div>
            </>
        );
    }
}
export default withTranslation()(IOAIApplicationJourney);
