import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Images
import Image1 from "../../../images/slider/slide3.jpg"
// import pic1 from "../../../images/slider/pic1.png"
// import pic2 from "../../../images/slider/pic2.png"
import pic3 from "../../../images/slider/pic3.png"
import pic4 from "../../../images/slider/pic4.png"

import { withTranslation } from 'react-i18next';

import ReactGA from 'react-ga';

class Slider2 extends Component {
	constructor(props) {
		super(props);
		this.next = this.next.bind(this);
		this.previous = this.previous.bind(this);
	}
	componentDidMount() {
		ReactGA.pageview(window.location.pathname);

		this.interval = setInterval(() => {
			this.next();
		  }, 8000);
	}
	componentWillUnmount() {
		clearInterval(this.interval);
	}
	next() {
		this.slider.slickNext();
	}
	previous() {
		this.slider.slickPrev();
	}
	render() {
		const { t } = this.props;
		const settings = {
			infinite: true,
			speed: 1000,
			slidesToShow: 1,
			slidesToScroll: 1,
			dots: true,
			initialSlide: 0,
		};

		return (
			<>
				<div className='slider-container'>
					<Slider ref={c => (this.slider = c)} {...settings} className="slideshow-container tt-slider slider-two slider-sp0 owl-none">
						<div className="slider-item" key={1}>
							<div className="slider-thumb">
								<img alt="" src={Image1} />
							</div>
							<div className="slider-content">
								<div className="container" >
									<div className="row align-items-center">
										<div className="col-lg-7 col-md-12 col-sm-12">
											<div className="content-inner">
												<h6 className="sub-title">
													{t('Slider1_Subtitle')}
												</h6>
												<h2 className="text-primary">
													{t('Slider1_Prititle2')}
													<a rel="noopener noreferrer" target="_blank" href="https://ioai-official.org/countries" style={{ textDecoration: "underline" }}>
														{t('Slider1_Prititle3')}
													</a>
													{t('Slider1_Prititle4')}
												</h2>
												<p>
													{t('Slider1_Description')}
												</p>
												<h2 className="text-important">{t('Slider1_New_Term')}</h2>
											</div>
										</div>
										<div className="col-lg-5 col-md-12 col-sm-12">
											<div className="slider-img" >
												<img alt="" className='rounded-lg' src={pic3} />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="slider-item" key={2}>
							<div className="slider-thumb">
								<img alt="" src={Image1} />
							</div>
							<div className="slider-content">
								<div className="container">
									<div className="row align-items-center">
										<div className="col-lg-7 col-md-12 col-sm-12">
											<div className="content-inner">
												<h6 className="sub-title">{t('Slider2_Subtitle')}</h6>
												<h2 className="text-primary">{t('Slider2_Prititle2')}</h2>
												<p>{t('Slider2_Description')}</p>
												<Link to="/courses-details"><h2 className="text-important">{t('Slider2_New_Term')}</h2></Link>
												<Link className="btn radius-xl m-r15 button-md white" to="/contact">{t('Slider2_Contact2')}</Link>
												<Link className="btn radius-xl button-md" to="/courses-details">{t('Slider2_ReadMore')}</Link>
											</div>
										</div>
										<div className="col-lg-5 col-md-12 col-sm-12">
											<div className="slider-img" style={{ width: '120%' }}>
												<img alt="" src={pic4} />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Slider>
				</div>
			</>
		);
	}
}

export default Slider2 = withTranslation()(Slider2);