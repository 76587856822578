import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import BackToTop from "./elements/back-top";
import PageScrollTop from "./elements/page-scroll-top";
import Index3 from "./pages/index-3";
import About1 from "./pages/about-1";
import Portfolio from "./pages/portfolio";
import Video from "./pages/video";
import News0 from "./pages/news-0";
import News1 from "./pages/news-1";
import News2 from "./pages/news-2";
import News3 from "./pages/news-3";
import IOAIIntroduction from "./pages/ioai-introduction";
import IOAIResource from "./pages/ioai-resource";
import IOAIApplication from "./pages/ioai-application";
import IOAIApplicationJourney from "./pages/ioai-application-journey";
import AOAITeam from "./pages/aoai-team";
import IOAISponsor from "./pages/ioai-sponsor";
import CoursesDetails from "./pages/courses-details";
import Contact2 from "./pages/contact-2";
import ReactGA from 'react-ga';

class Markup extends Component {
	componentDidMount() {
		ReactGA.pageview(window.location.pathname);
	} 
	render() {
		return (
			<>
				<BrowserRouter basename={"/"}>
					<Switch>
						<Route path="/" exact component={Index3} />
						<Route path="/courses-details" exact component={CoursesDetails} />
						<Route path="/news-0" exact component={News0} />
						<Route path="/news-1" exact component={News1} />
						<Route path="/news-2" exact component={News2} />
						<Route path="/news-3" exact component={News3} />
						<Route path="/ioai-introduction" exact component={IOAIIntroduction} />
						<Route path="/ioai-resource" exact component={IOAIResource} />
						<Route path="/ioai-sponsor" exact component={IOAISponsor} />
						<Route path="/ioai-application" exact component={IOAIApplication} />
						<Route path="/ioai-application-journey" exact component={IOAIApplicationJourney} />
						<Route path="/aoai-team" exact component={AOAITeam} />
						<Route path="/portfolio" exact component={Portfolio} />
						<Route path="/video" exact component={Video} />
						<Route path="/about" exact component={About1} />
						<Route path="/contact" exact component={Contact2} />
					</Switch>
					<PageScrollTop />
				</BrowserRouter>
				<BackToTop />
			</>
		);
	}
}

export default Markup;
