import React, { Component } from "react";
import { Link } from "react-router-dom";

// Layout
import Header from "../layout/header/header1";

// Images
import bannerImg from "../../images/banner/banner3.jpg";
import firstLegoLeagueImg from "../../images/courses/firstlegoleague.jpg";
import javaScriptImg from "../../images/courses/javascript.jpg";
import cAlgorithmImg from "../../images/courses/calgorithm.jpg";
import aioImg from "../../images/courses/aio.jpg";
import webDevelopmentImg from "../../images/courses/webdevelopment.jpg";
import aiMathImg from "../../images/courses/TheMathematicsofAI.png";
// import schedule1Img from "../../images/news/schedule1.jpg";

import { withTranslation } from "react-i18next";
import ReactGA from 'react-ga';

const courses = [
	{ id: 1, nameKey: "Course_Name_1", yearKey: "Course_Year_1", timeKey: "Course_Time_1" },
	{ id: 2, nameKey: "Course_Name_2", yearKey: "Course_Year_2", timeKey: "Course_Time_2" },
	{ id: 3, nameKey: "Course_Name_3", yearKey: "Course_Year_3", timeKey: "Course_Time_3" },
	{ id: 4, nameKey: "Course_Name_4", yearKey: "Course_Year_4", timeKey: "Course_Time_4" },
	{ id: 5, nameKey: "Course_Name_5", yearKey: "Course_Year_5", timeKey: "Course_Time_5" },
	{ id: 6, nameKey: "Course_Name_6", yearKey: "Course_Year_6", timeKey: "Course_Time_6" },
	{ id: 7, nameKey: "Course_Name_7", yearKey: "Course_Year_7", timeKey: "Course_Time_7" },
	{ id: 8, nameKey: "Course_Name_8", yearKey: "Course_Year_8", timeKey: "Course_Time_8" },
	{ id: 9, nameKey: "Course_Name_9", yearKey: "Course_Year_9", timeKey: "Course_Time_9" },
	{ id: 10, nameKey: "Course_Name_10", yearKey: "Course_Year_10", timeKey: "Course_Time_10" },
	{ id: 11, nameKey: "Course_Name_11", yearKey: "Course_Year_11", timeKey: "Course_Time_11" },
	{ id: 12, nameKey: "Course_Name_12", yearKey: "Course_Year_12", timeKey: "Course_Time_12" },
	{ id: 13, nameKey: "Course_Name_13", yearKey: "Course_Year_13", timeKey: "Course_Time_13" },
	{ id: 14, nameKey: "Course_Name_14", yearKey: "Course_Year_14", timeKey: "Course_Time_14" },
];

const courseInfoList = [
	{
		imgSrc: firstLegoLeagueImg,
		title: "CoursesDetails_LevelPre_H2",
		description: "CoursesDetails_LevelPre_P",
		level: "Level_Pre",
		duration: "Duration_Pre",
		language: "Language_Pre",
		age: "Age_Pre",
		courseDescr: "CoursesDetails_LevelPre_CourseP",
		certification: "CoursesDetails_LevelPre_CertificationP",
		outcomes: [
			"Outcome_Level_Pre_1",
			"Outcome_Level_Pre_2",
			"Outcome_Level_Pre_3",
			"Outcome_Level_Pre_4",
			"Outcome_Level_Pre_5",
		],
	},
	{
		imgSrc: javaScriptImg,
		title: "CoursesDetails_Level1_H2",
		description: "CoursesDetails_Level1_P",
		level: "Level_1",
		duration: "Duration_1",
		language: "Language_1",
		age: "Age_1",
		courseDescr: "CoursesDetails_Level1_CourseP",
		certification: "CoursesDetails_Level1_CertificationP",
		outcomes: [
			"Outcome_Level_1_1",
			"Outcome_Level_1_2",
			"Outcome_Level_1_3",
			"Outcome_Level_1_4",
			"Outcome_Level_1_5",
		],
	},
	{
		imgSrc: cAlgorithmImg,
		title: "CoursesDetails_Level2_H2",
		description: "CoursesDetails_Level2_P",
		level: "Level_2",
		duration: "Duration_2",
		language: "Language_2",
		age: "Age_2",
		courseDescr: "CoursesDetails_Level2_CourseP",
		certification: "CoursesDetails_Level2_CertificationP",
		outcomes: [
			"Outcome_Level_2_1",
			"Outcome_Level_2_2",
			"Outcome_Level_2_3",
			"Outcome_Level_2_4",
			"Outcome_Level_2_5",
			"Outcome_Level_2_6",
		],
	},
	{
		imgSrc: aioImg,
		title: "CoursesDetails_Level5_H2",
		description: "CoursesDetails_Level5_P",
		level: "Level_5",
		duration: "Duration_5",
		language: "Language_5",
		age: "Age_5",
		courseDescr: "CoursesDetails_Level5_CourseP",
		certification: "CoursesDetails_Level5_CertificationP",
		outcomes: [
			"Outcome_Level_5_1",
			"Outcome_Level_5_2",
			"Outcome_Level_5_3",
			"Outcome_Level_5_4",
			"Outcome_Level_5_5",
			"Outcome_Level_5_6",
		],
	},
	{
		imgSrc: webDevelopmentImg,
		title: "CoursesDetails_Level3_H2",
		description: "CoursesDetails_Level3_P",
		level: "Level_3",
		duration: "Duration_3",
		language: "Language_3",
		age: "Age_3",
		courseDescr: "CoursesDetails_Level3_CourseP",
		certification: "CoursesDetails_Level3_CertificationP",
		outcomes: [
			"Outcome_Level_3_1",
			"Outcome_Level_3_2",
			"Outcome_Level_3_3",
			"Outcome_Level_3_4",
			"Outcome_Level_3_5",
		],
	},
	{
		imgSrc: aiMathImg,
		title: "CoursesDetails_Level6_H2",
		description: "CoursesDetails_Level6_P",
		level: "Level_6",
		duration: "Duration_6",
		language: "Language_6",
		age: "Age_6",
		courseDescr: "CoursesDetails_Level6_CourseP",
		certification: "CoursesDetails_Level6_CertificationP",
		outcomes: [
			"Outcome_Level_6_1",
			"Outcome_Level_6_2",
			"Outcome_Level_6_3",
			"Outcome_Level_6_4",
			"Outcome_Level_6_5",
			"Outcome_Level_6_6",
			"Outcome_Level_6_7",
			"Outcome_Level_6_8",
		],
	},
	{
		imgSrc:'',
		title: "CoursesDetails_Level7_H2",
		description: "CoursesDetails_Level7_P",
		level: "Level_7",
		duration: "Duration_7",
		language: "Language_7",
		age: "Age_7",
		courseDescr: "CoursesDetails_Level7_CourseP",
		certification: "CoursesDetails_Level7_CertificationP",
		outcomes: [
			"Outcome_Level_7_1",
			"Outcome_Level_7_2",
			"Outcome_Level_7_3",
			"Outcome_Level_7_4",
			"Outcome_Level_7_5",
			"Outcome_Level_7_6",
			"Outcome_Level_7_7",
			"Outcome_Level_7_8",
		],
	},
];



class CoursesDetails extends Component {
	componentDidMount() {
		ReactGA.pageview(window.location.pathname);
	}

	render() {
		const { t } = this.props;

		return (
			<>
				<Header />
				<div className="page-content">
					<div className="page-banner ovbl-dark" style={{ backgroundImage: "url(" + bannerImg + ")" }}>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">{t("CoursesDetail_Title")}</h1>
							</div>
						</div>
					</div>
					<div className="breadcrumb-row">
						<div className="container">
							<ul className="list-inline">
								<li>
									<Link to="/">{t("CoursesDetail_LinkHome")}</Link>
								</li>
								<li>{t("CoursesDetail_LinkCourses")}</li>
							</ul>
						</div>
					</div>

					<div className="content-block">
						<div className="section-area section-sp1">
							<div className="container">
								<div className="row d-flex flex-row-reverse">
									<h1>{t("Schedule_Content_H")}</h1>
									<div className="col-xl-9 col-lg-8 col-md-12 col-sm-12">
										<div className="courses-post">
											<p>{t("Schedule_Date")}</p>
											<table className="table">
												<thead className="thead-light">
													<tr>
														<th scope="col"></th>
														<th scope="col">{t("Course_Table_Name")}</th>
														<th scope="col">{t("Course_Table_Year")}</th>
														<th scope="col">{t("Course_Table_Time")}</th>
													</tr>
												</thead>
												<tbody>
													{courses.map((course) => (
														<tr key={course.id}>
															<th scope="row">{course.id}</th>
															<td>{t(course.nameKey)}</td>
															<td>{t(course.yearKey)}</td>
															<td>{t(course.timeKey)}</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="content-block">
						<div className="section-area section-sp1">
							<div className="container">
								<div className="row d-flex flex-row-reverse">
									<div className="col-xl-9 col-lg-8 col-md-12 col-sm-12">
										<div className="courses-post">
											<h1>{t("CoursesDetails_Our_Courses")}</h1>
										</div>
										{courseInfoList.map((course, index) => (
											<div key={index}>
												<div className="courses-post">
													<div className="ttr-post-media media-effect">
														<Link to="#">
															<img alt="" src={course.imgSrc} />
														</Link>
													</div>
													<div className="ttr-post-info m-b30">
														<div className="ttr-post-title">
															<h2 className="post-title">{t(course.title)}</h2>
														</div>
														<div className="ttr-post-text">
															<p>{t(course.description)}</p>
														</div>
													</div>
												</div>
												<div className="course-overview" id="overview">
													<h4>{t("CoursesDetails_Overview")}</h4>
													<div className="row">
														<div className="col-md-12 col-lg-4">
															<ul className="course-features">
																<li>
																	<i className="ti-stats-up"></i>
																	<span className="label">{t("CoursesDetails_Common_4")}</span>
																	<span className="value">{t(course.level)}</span>
																</li>
																<li>
																	<i className="ti-time"></i>
																	<span className="label">{t("CoursesDetails_Common_3")}</span>
																	<span className="value">{t(course.duration)}</span>
																</li>
																<li>
																	<i className="ti-smallcap"></i>
																	<span className="label">{t("CoursesDetails_Common_5")}</span>
																	<span className="value">{t(course.language)}</span>
																</li>
																<li>
																	<i className="ti-book"></i>
																	<span className="label">{t("CoursesDetails_Common_1")}</span>
																	<span className="value">{t(course.age)}</span>
																</li>
															</ul>
														</div>
														<div className="col-md-12 col-lg-8">
															<h5 className="m-b10">{t("CoursesDetails_CommonCourseDescr")}</h5>
															<p>{t(course.courseDescr)}</p>
															<h5 className="m-b10">{t("CoursesDetails_CommonCertification")}</h5>
															<p>
																<b style={{ color: "blue" }}>{t(course.certification)}</b>
															</p>
															<h5 className="m-b10">{t("CoursesDetails_CommonOutcomes")}</h5>
															<ul className="list-checked primary">
																{course.outcomes.map((outcome, idx) => (
																	<li key={idx}>{t(outcome)}</li>
																))}
															</ul>
														</div>
													</div>
												</div>
												<br />
												<br />
												<br />
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default CoursesDetails = withTranslation()(CoursesDetails);
