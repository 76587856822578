import React, { Component } from "react";
import { Link } from "react-router-dom";

// Layout
import Header from "../layout/header/header1";

// Images
import bannerImg from "../../images/banner/banner3.jpg";
import news31Img from "../../images/news/news3_1.jpg";
import news32Img from "../../images/news/news3_2.jpg";
import news33Img from "../../images/news/news3_3.jpg";
import news34Img from "../../images/news/news3_4.jpg";

import { withTranslation } from "react-i18next";
import ReactGA from 'react-ga';
	
class News3 extends Component {
	componentDidMount() {
		ReactGA.pageview(window.location.pathname);
	}

	componentWillMount() {
		const { t } = this.props;
		document.getElementById('title').innerText = t("News3_Title");
	}

	componentWillUnmount() {
		const { t } = this.props;
		document.getElementById('title').innerText = t("Home_Title");
	}

	render() {
		const { t } = this.props;
		return (
			<>
				<Header />

				<div className="page-content">
					<div
						className="page-banner ovbl-dark"
						style={{ backgroundImage: "url(" + bannerImg + ")" }}
					>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">{t("News3_Title")}</h1>
							</div>
						</div>
					</div>
					<div className="breadcrumb-row">
						<div className="container">
							<ul className="list-inline">
								<li>
									<Link to="/">{t("News3_LinkHome")}</Link>
								</li>
								<li>{t("News3_LinkCourses")}</li>
							</ul>
						</div>
					</div>
					<div className="content-block">
						<div className="section-area section-sp1">
							<div className="container">
								<div className="row d-flex flex-row-reverse">
									<div className="col-xl-9 col-lg-8 col-md-12 col-sm-12">
										<div className="courses-post">
											<h1>{t("News3_Content_H")}</h1>
											<p>{t("News3_Content_Start")}<u><a rel="noopener noreferrer" href="https://mp.weixin.qq.com/s/IyF5uTa2fHDD7idQN-rDgg" target="_blank">{t("News3_Content_Link")}</a></u>{t("News3_Content_End")}</p>
											<p>{t("News3_Content_P1")}</p>
											<p style={{textAlign: "center"}}><img alt="" src={news31Img} /></p>
											<p>{t("News3_Content_P2")}</p>
											<p style={{textAlign: "center"}}><img alt="" src={news32Img} /></p>
											<p>{t("News3_Content_P3")}</p>
											<p style={{textAlign: "center"}}><img alt="" src={news33Img} /></p>
											<p>{t("News3_Content_P4")}</p>
											<p style={{textAlign: "center"}}><img alt="" src={news34Img} /></p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default News3 = withTranslation()(News3);
